<template>

<app-page>

	<app-page-head title="Embed" />

	<app-page-content :is-padded="true" class="embed">

		<p>You can embed your guide onto any webpage by copy and pasting the following code in the exact place you want the embed to appear.

		<p>The embed will responsively fill the available width while also dynamically expanding in height to show the full content of the guide.</p>

		<app-input-select label="Primary Category" placeholder="No primary category" v-model="model.primary" notes="A primary category is automatically open and placed at the top of the categories list." :options="categories" />

		<div class="embed-code" v-tooltip="'Click to copy'" v-on:click="onCodeClick">

			<div class="embed-code-copy" v-if="!is.copied">click to copy</div>
			<div class="embed-code-copy is-copied" v-if="is.copied">copied!</div>

			<div v-html="code" />

		</div>

	</app-page-content>

</app-page>

</template>

<script>

import copy from 'copy-to-clipboard'

export default {
	
	data: function() {

		return {
			is: {
				copied: false
			},
			model: {
				primary: 0
			}
		}

	},

	computed: {

		categories: function() {

			var options = {}

			this.$_.each(this.$store.getters['categories'], function(item) {

				options[item.id] = item.title

			})

			return options

		},

		codeDiv: function() {

			var code = []

			code.push('<div data-rbc-guide="' + this.$store.getters['guide/key'] + '"')

			if (this.model.primary) {

				code.push(' data-rbc-primary="' + this.model.primary + '"')

			}

			code.push('></div>')

			return code.join('')

		},

		codeScript: function() {

			// eslint-disable-next-line
			return '<script src="' + process.env.VUE_APP_EMBED + '"><\/script>'

		},

		code: function() {

			return [
				this.encode(this.codeDiv),
				'<br/>',
				this.encode(this.codeScript)
			].join('')

		}

	},

	methods: {

		encode: function(html) {

			return html.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');

		},

		onCodeClick: function() {

			copy(this.codeDiv + '\r' + this.codeScript)

			this.is.copied = true

		}

	}

}

</script>

<style scoped>

.embed p {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 20px;
}

.embed-code {
	background-color: #eee;
	padding: 30px 20px;
	font-family: 'Courier New', Courier, monospace;
	font-size: 12px;
	line-height: 20px;
	border-left: 10px solid #999;
	cursor: pointer;
}

.embed-code-copy {
	font-size: 10px;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 4px;
	position: absolute;
	right: 4px;
	top: 4px;
	padding: 0px 10px;
}

.embed-code-copy.is-copied {
	background-color: #4082d3;
}

</style>
